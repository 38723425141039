import _objectWithoutProperties from "/vercel/path1/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var __jsx = React.createElement;
import { Box, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
var useStyles = makeStyles({
  root: {
    display: 'inline-grid',
    placeItems: 'center'
  },
  buttonProgress: {
    color: 'inherit',
    gridColumn: '1',
    gridRow: '1'
  },
  children: {
    gridColumn: '1',
    gridRow: '1'
  },
  hidden: {
    visibility: 'hidden'
  }
}); // eslint-disable-next-line react/prop-types

export function MultiStateButton(_ref) {
  var isLoading = _ref.isLoading,
      children = _ref.children,
      rest = _objectWithoutProperties(_ref, ["isLoading", "children"]);

  var classes = useStyles();
  return __jsx(Button, rest, __jsx(Box, {
    component: "span",
    className: classes.root
  }, __jsx(Box, {
    className: clsx(classes.children, isLoading && classes.hidden)
  }, children), isLoading && __jsx(CircularProgress, {
    size: 18,
    className: classes.buttonProgress
  })));
}