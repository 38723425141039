var __jsx = React.createElement;
import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
var useStyles = makeStyles(function (theme) {
  return {
    loader: {
      backgroundColor: function backgroundColor(_ref) {
        var _backgroundColor = _ref.backgroundColor;
        return _backgroundColor;
      }
    }
  };
});

var Loader = function Loader(_ref2) {
  var backgroundColor = _ref2.backgroundColor,
      className = _ref2.className,
      message = _ref2.message,
      messageColor = _ref2.messageColor,
      info = _ref2.info;
  var classes = useStyles({
    backgroundColor: backgroundColor
  });
  return __jsx(Box, {
    className: clsx(classes.loader, className, 'loader'),
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 1111222222,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }, message && __jsx(Box, {
    color: messageColor
  }, message), info && __jsx(Box, {
    color: messageColor,
    className: "info"
  }, info));
};

Loader.defaultProps = {
  backgroundColor: '#ffffffb3',
  className: null,
  message: null,
  info: null,
  messageColor: null
};
export default Loader;