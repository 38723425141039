import _extends from "/vercel/path1/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "/vercel/path1/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var __jsx = React.createElement;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { withRouter } from 'next/router';
import Link from 'next/link';

var ActiveLink = function ActiveLink(_ref) {
  var router = _ref.router,
      children = _ref.children,
      className = _ref.className,
      href = _ref.href,
      activeClassName = _ref.activeClassName,
      onMouseEnter = _ref.onMouseEnter,
      onMouseLeave = _ref.onMouseLeave,
      target = _ref.target,
      props = _objectWithoutProperties(_ref, ["router", "children", "className", "href", "activeClassName", "onMouseEnter", "onMouseLeave", "target"]);

  var cls = className;
  if (router.pathname === href && activeClassName) cls += " ".concat(activeClassName);
  cls = cls ? cls.trim() : null;
  var dataProps = {};
  Object.keys(props).map(function (p) {
    if (p.startsWith('data-')) dataProps[p] = props[p];
    return p;
  });
  return __jsx(Link, _extends({}, props, {
    href: href
  }), __jsx("a", _extends({}, dataProps, {
    className: cls,
    target: target,
    onMouseEnter: onMouseEnter,
    onMouseLeave: onMouseLeave
  }), children));
};

ActiveLink.defaultProps = {
  router: {},
  children: null,
  className: null,
  target: '_self',
  activeClassName: null,
  onMouseEnter: function onMouseEnter() {},
  onMouseLeave: function onMouseLeave() {}
};
export default withRouter(ActiveLink);